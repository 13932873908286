import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/session/app-session.service';


@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
    constructor(private _permissionChecker: PermissionCheckerService, private _router: Router, private _sessionService: AppSessionService) {}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkPermissions(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkPermissions(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/account/login';
        }

        return '/app/home';
    }

    private checkPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this._sessionService.user) {
            void this._router.navigate(['/account/login']);
            return false;
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }

        void this._router.navigate([this.selectBestRoute()]);
        return false;
    }
}
