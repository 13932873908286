import { environment } from '@environments/environment';
import { MenuItem } from './layout/menu-item';
import { PermissionConsts } from './permission-consts';

export class AppConsts {
    static experienceLink = environment.experiencesLink;
    static contactsLink = environment.contactsLink;
    static dihNodeCoop = environment.dihNodeCoop;

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static googleAnalyticsId: string = '';

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'NodeDIHPrivatePlatform',
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token',
    };

    static goToWebinar: {
        clientId: string;
        secret: string;
    };

    static goToWebinarTokenUrl = 'https://authentication.logmeininc.com/oauth/token';
    static goToWebinarRedirectUrl = 'https://authentication.logmeininc.com/oauth/authorize?client_id={0}&redirect_uri={1}&response_type=code&state={2}';
    static goToWebinarTokenRoute = 'logmeininc.com/oauth/token';
    static goToWebinarClientId: string;
    static goToWebinarSecret: string;
    static userPreferenceDefault: string =
        'future-events-home-list,news-blog-list,user-saved-contract-list,training-course-user-list,contract-training-course-latest,digital-assessment-after-three-months';

    static menuItem = [
        new MenuItem('HomePage', 'home', 'fas fa-home', 'HomePage'),
        new MenuItem('Profile', 'my-profile', 'fas fa-user', 'Profile'),
        new MenuItem('Blog', 'news', 'fas fa-newspaper', 'Blog'),
        new MenuItem('Events', 'events', 'fas fa-calendar', 'Events', PermissionConsts.Pages_Events),
        new MenuItem('DigitalAssessment', 'digital-assessment', 'fas fa-smile', 'DigitalAssessment', PermissionConsts.Pages_SurveyCompilations),
        new MenuItem('CompilationsAssessment', 'digital-assessment-compilations', 'fas fa-list', 'CompilationsAssessment', PermissionConsts.Pages_AdminSurveyCompilations),
        new MenuItem('Contract', 'contract', 'fas fa-chart-line', 'Contract'),
        new MenuItem('TrainingCourses', 'training-course', 'fas fa-graduation-cap', 'TrainingCourses'),
        new MenuItem('Experiences', this.experienceLink, 'fas fa-flag-checkered', 'Experiences', null, null, false),
        new MenuItem('Contacts', this.contactsLink, 'fas fa-info-circle', 'Contacts', null, null, true),
        new MenuItem('Dih.Node.Coop', this.dihNodeCoop, 'fas fa-globe', 'Contacts', null, null, true),
        new MenuItem('ManageUser', 'users', 'fas fa-cog', 'Admin', PermissionConsts.Pages_Users),
        new MenuItem('Statistics', 'statistics', 'fas fa-chart-bar', 'Statistics', PermissionConsts.Pages_AdminDashboard),
    ];

    static DigitalAssessmentSurvey = 'DigitalAssessmentSurvey';
    static TrainingCourseSurvey = 'TrainingCourseSurvey';

    static readonly socialWithMessanger = ['facebook', 'twitter', 'linkedin', 'whatsapp', 'messenger', 'telegram', 'email'];
    static readonly socialWithoutMessanger = ['facebook', 'twitter', 'linkedin', 'whatsapp', 'telegram', 'email'];

    static Insufficient = 'Insufficient';
    static Intermediate = 'Intermediate';
    static Advanced = 'Advanced';
}
