import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class FileDownloadHelper {
    private subscription: Subscription = new Subscription();

    constructor() {}

    downloadByteArray(data: string, filename: string, mimeType: string) {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);

        const binaryData = atob(data);
        const blob = this.base64toBlob(binaryData, mimeType);
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    private base64toBlob(byteString, type): Blob {
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type });
    }
}
