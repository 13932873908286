import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { TokenService } from 'abp-ng2-module';
import { environment } from '@environments/environment';

declare global {
    interface Window {
        sendMessageToAlgho: (command: any, param?: any) => void;
    }
}

@Injectable()
export class AlghoDhiService implements OnDestroy {
    private subscription = new Subscription();

    constructor(private tokenService: TokenService) {}

    public init() {
        const sub = timer(100).subscribe(() => {
            const token = this.tokenService.getToken();

            const tag = document.createElement('algho-viewer');
            tag.setAttribute('bot-id', environment.alghoBotId);
            tag.setAttribute('cookie-domain', environment.alghoBotCookieDomain);
            tag.setAttribute('widget', 'true');
            tag.setAttribute('audio', 'true');
            tag.setAttribute('voice', 'true');
            tag.setAttribute('dhi', 'true');
            tag.setAttribute('floating', 'true');
            tag.setAttribute('transparent', 'true');
            tag.setAttribute('open', 'false');
            tag.setAttribute('z-index', '10005');

            let tokenQueryParams = '';
            if (token) {
                tokenQueryParams = `?token=${token}`;
                tag.setAttribute('token', token);
            }

            document.body.appendChild(tag);

            const script = document.createElement('script');
            script.setAttribute('id', 'algho-viewer-module');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('defer', 'defer');
            script.setAttribute('src', 'https://virtualassistant.alghoncloud.com/algho-viewer.min.js' + tokenQueryParams);
            document.body.appendChild(script);
        });
        this.subscription.add(sub);
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public setAuthToken(token: string): void {
        if (window.sendMessageToAlgho) {
            window.sendMessageToAlgho('set-token', token);
        }
    }

    public openNotch(): void {
        if (window.sendMessageToAlgho) {
            window.sendMessageToAlgho('open-notch');
        }
    }

    public closeNotch(): void {
        if (window.sendMessageToAlgho) {
            window.sendMessageToAlgho('close-notch');
        }
    }

    public showBotMessage(message: string): void {
        if (window.sendMessageToAlgho) {
            window.sendMessageToAlgho('show-bot-message', message);
        }
    }

    public showUserMessage(message: string): void {
        if (window.sendMessageToAlgho) {
            window.sendMessageToAlgho('show-user-message', message);
        }
    }
}
