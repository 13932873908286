import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoToWebinarService } from '@shared/services/go-to-webinar.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class GoToWebinarInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request?.url.includes(AppConsts.goToWebinarTokenRoute)) {
            const headers: HttpHeaders = request.headers.set('Authorization', GoToWebinarService.getBase64String()).set('Content-Type', 'application/x-www-form-urlencoded');
            request = request.clone({
                headers: headers,
            });
        }
        return next.handle(request);
    }
}
