import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { GoToWebinarInterceptor } from '@shared/interceptors/go-to-webinar.interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.SurveyServiceProxy,
        ApiServiceProxies.TrainingCourseServiceProxy,
        ApiServiceProxies.TrainingCourseLessonChapterServiceProxy,
        ApiServiceProxies.BannerServiceProxy,
        ApiServiceProxies.AdminDashboardServiceProxy,
        ApiServiceProxies.TrainingCourseLessonUserServiceProxy,
        ApiServiceProxies.InfocicaProvinceServiceProxy,
        ApiServiceProxies.ContractServiceProxy,
        ApiServiceProxies.GoToServiceProxy,
        ApiServiceProxies.PlannedEventServiceProxy,
        ApiServiceProxies.ParticipationModeServiceProxy,
        ApiServiceProxies.PlannedEventUserServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: GoToWebinarInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
