import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import * as moment from '@node_modules/moment';
import { delay, interval, mapTo, throwError } from '@node_modules/rxjs';
import { BehaviorSubject, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { LocalizationService, MessageService, PermissionCheckerService } from 'abp-ng2-module';
import { ConfigurationServiceProxy, CreateGoToTokenDto, GoToServiceProxy, GoToTokenDto, GoToWebinarSetting } from '@shared/service-proxies/service-proxies';
import { GoToWebinarToken } from '@shared/model/go-to-webinar-token';
import { PermissionConsts } from '@shared/permission-consts';

const redirectUriName = 'redirectUri';

@Injectable({
    providedIn: 'root',
})
export class GoToWebinarService {
    token$ = new BehaviorSubject<GoToWebinarToken>(null);
    private _refreshing: boolean = false;
    private _momentDate: moment.Moment;
    tokenId?: string;

    private goToWebinarSetting?: GoToWebinarSetting;

    get timer$(): Observable<string | null> {
        return interval(1000).pipe(
            map(() => {
                if (!this.momentDate) {
                    return null;
                }
                const b: moment.Moment = moment();
                const diff = this.momentDate.diff(b);
                const d = moment.duration(diff);
                return this.pad(d.hours()) + ':' + this.pad(d.minutes()) + ':' + this.pad(d.seconds());
            })
        );
    }

    private pad(num: number): string {
        return String(num).padStart(2, '0');
    }

    private get momentDate(): moment.Moment | null {
        if (!this._momentDate || !this._momentDate.isValid() || this._momentDate.isBefore(moment())) {
            return null;
        }
        return this._momentDate;
    }

    get baseRedirectUri(): string {
        return AppConsts.appBaseUrl + '/app/dispatcher/goto';
    }

    static get baseRedirectUri(): string {
        return AppConsts.appBaseUrl + '/app/dispatcher/goto';
    }

    get redirectUri(): string {
        return localStorage.getItem(redirectUriName);
    }

    get refreshing(): boolean {
        return this._refreshing;
    }

    constructor(
        private http: HttpClient,
        private goToService: GoToServiceProxy,
        private router: Router,
        private message: MessageService,
        private localization: LocalizationService,
        private configuration: ConfigurationServiceProxy,
        private permissionCheckerService: PermissionCheckerService
    ) {
        if (this.permissionCheckerService.isGranted(PermissionConsts.Planned_Event_GetAllExpired)) {
            this.goToService.getGoToToken().subscribe((res: GoToTokenDto) => {
                if (res.token) {
                    this.token$.next(JSON.parse(res.token) as GoToWebinarToken);
                    this.tokenId = res.id;
                    this.setTimer(res);
                }
            });
            this.setConfiguration().subscribe();
        }
    }

    redirect(redirectUri?: string): void {
        if (!redirectUri) {
            redirectUri = this.router.url;
        }
        localStorage.setItem(redirectUriName, redirectUri);
        this.message.warn(this.l('RedirectingForToken'));
        abp.ui.setBusy(document.body);
        this.setConfiguration()
            .pipe(delay(1500))
            .subscribe(() => {
                location.href = abp.utils.formatString(AppConsts.goToWebinarRedirectUrl, this.goToWebinarSetting?.clientId, GoToWebinarService.baseRedirectUri, redirectUri);
            });
    }

    getToken(code: string): Observable<GoToWebinarToken> {
        let objectParams: any;
        let body: HttpParams;
        return this.setConfiguration().pipe(
            tap((res: { [key: string]: any }) => {
                objectParams = {
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: this.baseRedirectUri,
                    client_id: res.goToWebinarClientId,
                };
                body = new HttpParams({ fromObject: objectParams });
            }),
            switchMap(() => this.http.post(AppConsts.goToWebinarTokenUrl, body.toString()).pipe(switchMap((res: GoToWebinarToken) => this.setToken(res)))),
            catchError((err: HttpErrorResponse) => {
                this.message.error(err.error?.error_description);
                return throwError(err);
            })
        );
    }

    private setConfiguration(): Observable<any> {
        return this.configuration.getGoToWebinarConfig().pipe(
            map((res: GoToWebinarSetting) => {
                this.goToWebinarSetting = res;
                AppConsts.goToWebinarClientId = res.clientId;
                AppConsts.goToWebinarSecret = res.secret;
                return AppConsts;
            })
        );
    }

    private l(str: string): string {
        return this.localization.localize(str, AppConsts.localization.defaultLocalizationSourceName);
    }

    private setTimer(dbRes?: GoToTokenDto) {
        if (dbRes) {
            this._momentDate = dbRes.expirationDate;
        } else {
            const seconds = this.token$?.value?.expires_in;
            this._momentDate = moment().add(seconds, 'seconds').subtract(60, 'seconds');
        }
        if (!this.momentDate) {
            return;
        }
        of(null)
            .pipe(delay(this.momentDate.toDate()))
            .subscribe(() => this.refreshToken());
    }

    clear() {
        this._refreshing = true;
        this.goToService
            .deleteGoToToken(this.tokenId)
            .pipe(finalize(() => (this._refreshing = false)))
            .subscribe(() => {
                this._momentDate = undefined;
                this.token$.next(undefined);
                this.tokenId = undefined;
            });
    }

    refreshToken(manual: boolean = false): void {
        this._refreshing = true;
        const objectParams = {
            grant_type: 'refresh_token',
            refresh_token: this.token$?.value?.refresh_token,
        };
        const body: HttpParams = new HttpParams({ fromObject: objectParams });
        this.http.post(AppConsts.goToWebinarTokenUrl, body.toString()).subscribe({
            next: (token: GoToWebinarToken) => {
                if (token.refresh_token) {
                    this.setToken(token);
                }
            },
            error: () => {
                if (!manual) {
                    this.clear();
                }
                this._refreshing = false;
            },
            complete: () => (this._refreshing = false),
        });
    }

    static getBase64String(): string {
        return 'Basic ' + btoa(AppConsts.goToWebinarClientId + ':' + AppConsts.goToWebinarSecret);
    }

    private setToken(token: GoToWebinarToken, writeToDb: boolean = true): Observable<GoToWebinarToken> {
        this.token$.next(token);
        const tokenExpiresMoment = moment().add(token.expires_in, 'seconds');
        this.setTimer();
        const tokenString = JSON.stringify(token);
        if (writeToDb) {
            return this.goToService
                .createGoToToken(
                    new CreateGoToTokenDto({
                        expirationDate: tokenExpiresMoment,
                        token: tokenString,
                    })
                )
                .pipe(mapTo(token));
        }
        return of(token);
    }
}
