export enum PermissionConsts {
    Pages_Users = 'Pages.Users',
    User_Create = 'Users.Create',
    User_Detail = 'User.Detail',
    User_Edit = 'User.Edit',
    User_Delete = 'User.Delete',
    User_Get = 'User.Get',
    UserInvitation_SendInvitation = 'UserInvitation.SendInvitation',
    User_Deactivate = 'User.Deactivate',
    User_UnlockLogin = 'User.UnlockLogin',
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_ChangePassword = 'User.ChangePassword',

    Pages_Contracts = 'Pages.Contracts',
    Pages_TrainingCourses = 'Pages.TrainingCourses',
    Pages_SurveyCompilations = 'Pages.SurveyCompilations',
    Pages_AdminDashboard = 'Pages.AdminDashboard',
    Pages_AdminSurveyCompilations = 'Pages.AdminSurveyCompilations',

    Contract_GetAll = 'Contract.GetAll',
    Contract_Get = 'Contract.Get',
    Contract_DownloadOverview = 'Contract.DownloadOverview',
    ContractInfoCICA_GetAll = 'ContractInfoCICA.GetAll',
    ContractManuallyInserted_GetAll = 'ContractManuallyInserted.GetAll',
    Contract_Create = 'Contract.Create',

    TrainingCourse_Create = 'TrainingCourse.Create',
    TrainingCourse_Update = 'TrainingCourse.Update',
    TrainingCourse_ManageImages = 'TrainingCourse.ManageImages',

    TrainingCourseLesson_Create = 'TrainingCourseLesson.Create',
    TrainingCourseLesson_Update = 'TrainingCourseLesson.Update',
    TrainingCourseLesson_ManageThumbnail = 'TrainingCourseLesson.ManageThumbnail',
    TrainingCourseLesson_ManageFile = 'TrainingCourseLesson.ManageFile',

    TrainingCourseChapter_GetAll = 'TrainingCourseChapter.GetAll',
    TrainingCourseChapter_Create = 'TrainingCourseChapter.Create',
    TrainingCourseChapter_Delete = 'TrainingCourseChapter.Delete',
    TrainingCourseChapter_Update = 'TrainingCourseChapter.Update',

    File_Create = 'File.Create',

    SurveyCompilation_DownloadReportWord = 'SurveyCompilation.DownloadReportWord',

    Planned_Event_GetAll = 'PlannedEvent.GetAll',
    Planned_Event_GetInfo = 'PlannedEvent.DownloadInfo',
    Pages_Events = 'Pages.PlannedEvents',
    Planned_Event_GetAllExpired = 'PlannedEvent.GetAllExpired',
    PlannedEvent_Manage = 'PlannedEvent.Manage',
    PlannedEvent_Get = 'PlannedEvent.Get',

    PlannedEventUser_GetAll = 'PlannedEventUser.GetAll',
    PlannedEventUser_ExportExcel = 'PlannedEventUser.ExportExcel',

    News_DownloadFeed = 'News.DownloadFeed',
}
