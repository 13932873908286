import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { LayoutConfig } from './layout-config';

@Injectable()
export class LayoutStoreService {
    public readonly config$: Observable<LayoutConfig>;

    private renderer2: Renderer2;
    private isMobile = true;
    private readonly initialLayoutConfig: LayoutConfig = {
        sidebarCollapsed: false,
    };
    private configSource: BehaviorSubject<LayoutConfig>;

    constructor(private rendererFactory: RendererFactory2, private deviceService: DeviceDetectorService) {
        this.renderer2 = rendererFactory.createRenderer(null, null);

        this.defineDeviceProperty();
        this.configSource = new BehaviorSubject(this.initialLayoutConfig);
        this.config$ = this.configSource.asObservable();
        // this.initialLayoutConfig.sidebarCollapsed = this.isMobile;
    }

    get sidebarExpanded(): Observable<boolean> {
        return this.config$.pipe(pluck('sidebarCollapsed'), distinctUntilChanged()) as Observable<boolean>;
    }

    public setSidebarExpanded(value: boolean): void {
        this.configSource.next(Object.assign(this.configSource.value, { sidebarCollapsed: value }));
    }

    public isMobileMode(): boolean{
        return this.isMobile;
    }

    private defineDeviceProperty() {
        this.isMobile = this.deviceService.isMobile();
        if (this.isMobile) {
            this.renderer2.addClass(document.body, 'is-mobile-device');
        }

        const isTablet = this.deviceService.isTablet();
        if (isTablet) {
            this.renderer2.addClass(document.body, 'is-tablet-device');
        }

        const isDesktopDevice = this.deviceService.isDesktop();
        if (isDesktopDevice) {
            this.renderer2.addClass(document.body, 'is-desktop-device');
        }
    }
}
