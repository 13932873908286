export class MenuItem {
    id: number;
    parentId: number;
    label: string;
    route: string;
    icon: string;
    permissionName: string;
    isActive?: boolean;
    isCollapsed?: boolean;
    children: MenuItem[];
    unlocalizedLabel: string;
    blank: boolean = true;

    constructor(label: string, route: string, icon: string,unlocalizedLabel: string, permissionName: string = null, children: MenuItem[] = null, blank: boolean = false) {
        this.label = label;
        this.route = route;
        this.icon = icon;
        this.permissionName = permissionName;
        this.children = children;
        this.unlocalizedLabel = unlocalizedLabel;
        this.blank = blank;
    }
}
