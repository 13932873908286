// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.staging.json',
    defaultTenantId: 1,
    datePickerThemeClass: 'theme-dark-blue',
    alghoBotId: '82b191fff905ba0d24fb84fb0bfa694e',
    alghoBotCookieDomain: '.nodetest.it',
    privacyPolicy: 'https://www.nodedih.nodetest.it/Privacy',
    alghoPrivacyPolicy: 'https://www.nodedih.nodetest.it/Privacy-Coopera',
    termsAndCondition: 'https://www.nodedih.nodetest.it/Termini-di-utilizzo',
    experiencesLink: 'https://www.nodedih.nodetest.it/Proposte/Percorsi-di-innovazione/#LeEsperienze',
    contactsLink:'https://www.nodedih.nodetest.it/Contatti',
    recaptchaKey: '6Lce2dseAAAAACRA2lrSYqOSIwYF-RNx9k4IrMMz',
    dihNodeCoop: 'https://www.nodedih.nodetest.it'
};
