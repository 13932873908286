import { AfterViewInit, Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppConsts } from '@shared/AppConsts';
import { AlghoDhiService } from '@shared/helpers/algho-dhi.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class RootComponent implements OnInit, AfterViewInit, OnDestroy {
    private subscription = new Subscription();

    constructor(private router: Router, private alghoDhiService: AlghoDhiService) {}

    ngOnInit() {
        if (!isDevMode() && AppConsts.googleAnalyticsId) {
            this.setUpAnalytics();
        }
    }

    public ngAfterViewInit(): void {
        this.alghoDhiService.init();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setUpAnalytics() {
        const sub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            gtag('config', AppConsts.googleAnalyticsId, {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                page_path: event.urlAfterRedirects,
            });
        });
        this.subscription.add(sub);
    }
}
