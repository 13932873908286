import { TrainingCourseContentTypeServiceProxy } from './service-proxies/service-proxies';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppSessionService } from '@shared/session/app-session.service';
import { AppUrlService } from '@shared/nav/app-url.service';
import { AppAuthService } from '@shared/auth/app-auth.service';
import { AppRouteGuard } from '@shared/auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from '@shared/components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from '@shared/components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from '@shared/components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from '@shared/components/modal/abp-modal-footer.component';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { BusyDirective } from '@shared/directives/busy.directive';
import { EqualValidator } from '@shared/directives/equal-validator.directive';
import {
    CompanyServiceProxy,
    CompanyUserServiceProxy,
    FileServiceProxy,
    InfocicaApplicationServiceProxy,
    InfocicaBeneficiaryServiceProxy,
    InfocicaEligibleExpenditureServiceProxy,
    InfocicaFacilitationServiceProxy,
    InfocicaRegionServiceProxy,
    NewsServiceProxy,
    SectorServiceProxy,
    SurveyCompilationServiceProxy,
    TrainingCourseLessonServiceProxy,
    TrainingCourseServiceProxy,
    TrainingCourseTopicServiceProxy,
    UserInvitationServiceProxy,
    UserPreferenceContractServiceProxy,
    UserPreferenceTrainingCourseServiceProxy,
    UserSavedContractServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { TrainingCourseFilterComponent } from '@shared/components/training-course-filter/training-course-filter.component';
import { ContractFilterComponent } from '@shared/components/contract-filter/contract-filter.component';
import { FileDownloadHelper } from '@shared/helpers/FileDownloadHelper';
import { AlghoDhiService } from '@shared/helpers/algho-dhi.service';
import { UserFilterComponent } from '@shared/components/user-filter/user-filter.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PrivacyCooperaComponent } from '@shared/components/privacy-coopera/privacy-coopera.component';
import { StatisticsGenericListComponent } from './components/statistics-generic-list/statistics-generic-list.component';
import { StatisticsGenericItemComponent } from './components/statistics-generic-item/statistics-generic-item.component';
import { TooltipModule } from '@node_modules/ngx-bootstrap/tooltip';
import { CompilationAdminFilterComponent } from './components/compilation-admin-filter/compilation-admin-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SwiperModule } from 'swiper/angular';
import { UserSavedContractListComponent } from './components/user-saved-contract-list/user-saved-contract-list.component';
import { UserSavedContractItemComponent } from './components/user-saved-contract-item/user-saved-contract-item.component';
import { NewsBlogListComponent } from './components/news-blog-list/news-blog-list.component';
import { NewsBlogItemComponent } from './components/news-blog-item/news-blog-item.component';
import { TrainingCourseUserListComponent } from './components/training-course-user-list/training-course-user-list.component';
import { TrainingCourseUserItemComponent } from './components/training-course-user-item/training-course-user-item.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { GoToWebinarAuthenticatorComponent } from './components/go-to-webinar/go-to-webinar-authenticator.component';
import { DigitalAssessmentAfterThreeMonthsComponent } from './components/digital-assessment-after-three-months/digital-assessment-after-three-months.component';
import { FutureEventsHomeListComponent } from './components/future-events-home-list/future-events-home-list.component';
import { FutureEventsHomeItemComponent } from './components/future-events-home-item/future-events-home-item.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressbarModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule,
        SwiperModule,
        BsDatepickerModule.forRoot(),
        PopoverModule.forRoot(),
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        ContractFilterComponent,
        TrainingCourseFilterComponent,
        UserFilterComponent,
        PrivacyCooperaComponent,
        StatisticsGenericListComponent,
        StatisticsGenericItemComponent,
        CompilationAdminFilterComponent,
        UserSavedContractListComponent,
        UserSavedContractItemComponent,
        NewsBlogListComponent,
        NewsBlogItemComponent,
        TrainingCourseUserListComponent,
        TrainingCourseUserItemComponent,
        GoToWebinarAuthenticatorComponent,
        FutureEventsHomeListComponent,
        FutureEventsHomeItemComponent,
        DigitalAssessmentAfterThreeMonthsComponent,
        CopyToClipboardDirective,
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        PrivacyCooperaComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        NgSelectModule,
        ContractFilterComponent,
        TrainingCourseFilterComponent,
        InfiniteScrollModule,
        ProgressbarModule,
        UserFilterComponent,
        StatisticsGenericListComponent,
        StatisticsGenericItemComponent,
        CompilationAdminFilterComponent,
        UserSavedContractListComponent,
        NewsBlogListComponent,
        TrainingCourseUserListComponent,
        GoToWebinarAuthenticatorComponent,
        FutureEventsHomeListComponent,
        DigitalAssessmentAfterThreeMonthsComponent,
        CopyToClipboardDirective,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService,
                AlghoDhiService,
                SectorServiceProxy,
                CompanyServiceProxy,
                InfocicaApplicationServiceProxy,
                InfocicaFacilitationServiceProxy,
                InfocicaRegionServiceProxy,
                InfocicaBeneficiaryServiceProxy,
                UserPreferenceContractServiceProxy,
                UserPreferenceTrainingCourseServiceProxy,
                TrainingCourseTopicServiceProxy,
                TrainingCourseContentTypeServiceProxy,
                InfocicaEligibleExpenditureServiceProxy,
                UserInvitationServiceProxy,
                CompanyUserServiceProxy,
                SurveyCompilationServiceProxy,
                TrainingCourseLessonServiceProxy,
                TrainingCourseServiceProxy,
                UserSavedContractServiceProxy,
                FileServiceProxy,
                FileDownloadHelper,
                NewsServiceProxy,
            ],
        };
    }
}
